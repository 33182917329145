<template>
    <div>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-btn :loading="api.isLoading" @click="validateInput" color="error" plain>
            Delete
        </v-btn>
    </div>
</template>

<script>
import AConfirmation from '../../components/common/AConfirmation.vue';

    export default{
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            this.api.isLoading = false;
            this.api.isError = false;
            this.api.isSuccesful = true;
            if (resp.class == "deleteTeam") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully delete the team";
                this.$router.push({ name: "PageTeamList" });
            }
        };
    },
    data: () => ({
        isPending: false,
        api: {
            isLoading: false,
            isError: false,
            isSuccesful: false,
            sucess: "",
            error: null,
            url: null,
        },
    }),
    methods: {
        validateInput() {
            this.isPending = true;
        },
        submit() {
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url=process.env.VUE_APP_SERVER_API+"/team";
            tempApi.params={
                'name':this.$_.clone(this.$store.getters.getTeam)
            }
            this.$api.fetch(tempApi);
        },
        cancelSubmit() {
            this.isPending = false;
        },
    },
    components: { AConfirmation }
}
</script>